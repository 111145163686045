<template>
  <div class="dialogue wide">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-5">
        <label>First name</label>
        <input type="text" v-model="user.first_name">
      </div>
      <div class="cell small-12 medium-5">
        <label>Last name</label>
        <input type="text" v-model="user.last_name">
      </div>
      <div class="cell small-12 medium-2">
        <label>Initials</label>
        <input type="text" v-model="user.initials">
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6">
        <label>Email</label>
        <input type="text" v-model="user.email">
      </div>
      <div class="cell small-12 medium-6">
        <label>Job Title</label>
        <select v-model="user.job_title_id">
          <option v-for="(title, index) in jobTitles" :key="`t_${index}`" :value="title.id">
            {{ title.title }}
          </option>
        </select>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6 medium-offset-6 text-right">
        <button @click="saveUpdate" class="button small">Save</button>
        &nbsp;
        <button @click="cancel" class="button small red">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../../../axios';

export default {
  name: 'AdminUsersComponentsEdit',
  props: [
    'userId',
  ],
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      jobTitles: [],
      user: {
        first_name: '',
        last_name: '',
        email: '',
        job_title_id: 0,
      },
    };
  },
  methods: {
    saveUpdate() {
      const postData = this.user;
      axios.post(`/users/update/${this.userId}.json?token=${this.token}`, postData)
        .then((response) => {
          this.$emit('editSaved', response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUser() {
      axios.get(`/users/getOne/${this.userId}.json?token=${this.token}`)
        .then((response) => {
          this.user = response.data.user;
          this.getJobTitles();
        })
        .catch(() => {
          this.failed = true;
        });
    },
    getJobTitles() {
      axios.get(`/jobTitles/fetchAll.json?token=${this.token}`)
        .then((response) => {
          this.jobTitles = response.data.jobTitles;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>
