<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-9">
        <h1 class="pb-5">System Users</h1>
      </div>
      <div class="cell small-8 medium-3 text-right">
        <button @click="addUser = true" class="button expanded">
          Add System User
        </button>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Initials</th>
              <th>Job Title</th>
              <th>Email</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(user, index) in users"
              :key="`u_${index}`">
              <td>{{ user.first_name }} {{ user.last_name }}</td>
              <td>{{ user.initials }}</td>
              <td>{{ user.job_title.title }}</td>
              <td>{{ user.email }}</td>
              <td class="text-right">
                <span
                  class="material-symbols-outlined icon-yellow icon-link"
                  @click="userInEdit = user, userInEditIndex = index"
                  >edit</span>
                &nbsp;
                <span
                  class="material-symbols-outlined icon-red icon-link"
                  @click="deleteUser(index)"
                  >delete</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <button v-if="page > 1" @click="changePage('minus')" class="button">Prev Page</button>
        &nbsp;
        <button v-if="page < length" @click="changePage('plus')" class="button">Next Page</button>
      </div>
    </div>
    <Edit
      :userId="userInEdit.id"
      v-if="userInEditIndex > -1"
      @cancel="userInEdit = {}, userInEditIndex = -1"
      @editSaved="editSaved" />
    <Create
      v-if="addUser === true"
      @cancel="addUser = false"
      @createSaved="createSaved" />
    <Message
      v-if="showMessage === true"
      :messageText="message"
      :link="false"
      :linkText="false"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import Create from '@/views/admin/users/components/Create.vue';
import Edit from '@/views/admin/users/components/Edit.vue';
import Message from '@/components/messages/Message.vue';
import axios from '../../../axios';

export default {
  name: 'SystemUsersIndex',
  components: {
    Create,
    Edit,
    Message,
  },
  data() {
    return {
      addUser: false,
      length: 1,
      message: '',
      page: 1,
      showMessage: false,
      users: [],
      userInEdit: {},
      userInEditIndex: -1,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    createSaved(result) {
      this.users.push(result.user);
      this.addUser = false;
      this.message = result.message;
      this.showMessage = true;
    },
    editSaved(result) {
      this.users[this.userInEditIndex] = result.user;
      this.userInEdit = {};
      this.userInEditIndex = -1;
      this.message = result.message;
      this.showMessage = true;
    },
    deleteUser(index) {
      const userId = this.users[index].id;
      axios.get(`/users/delete/${userId}.json?token=${this.token}`)
        .then(() => {
          this.users.splice(index, 1);
        })
        .catch(() => {
          this.failed = true;
        });
    },
    getAllUsers() {
      axios.get(`/users/getAll/${this.page}.json?token=${this.token}`)
        .then((response) => {
          this.users = response.data.users;
          this.length = response.data.length;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    changePage(direction) {
      if (direction === 'plus') this.page += 1;
      if (direction === 'minus') this.page -= 1;
      this.getAllUsers();
    },
  },
  mounted() {
    setTimeout(() => {
      this.getAllUsers();
    }, 100);
  },
};
</script>
